<!--用户登录-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="1" ></el-col>
      <el-col :sm="24" :md="22" :lg="22" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; 用户登录
      </el-col>
      <el-col :sm="0" :md="1" :lg="1"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p login_bg">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="1" ></el-col>
      <el-col :sm="12" :md="11" :lg="11" >
        <img src="@/assets/images/login.jpg" alt=""/>
      </el-col>
      <el-col :sm="12" :md="11" :lg="11">
        <div class="login_box">                    
          <div class="login_tit">用户登录</div>          
          <el-form ref="formRef" :model="form" :rules="rules" size="large">
            <el-form-item prop="username">
              <el-input v-model="form.username" placeholder="请输入用户名" prefix-icon="User"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="form.password" show-password placeholder="请输入登录密码" prefix-icon="lock"></el-input>
            </el-form-item>
            <!-- <el-form-item prop="vcode">
              <el-input v-model="form.vcode" placeholder="请输入验证码" prefix-icon="CircleCheck" class="vcode" style="width:140px"></el-input>
              <img :src="vcodeImg" alt="看不清?点击更换" onclick="this.src = this.src + '?'" class="vcode" />
            </el-form-item> -->
            <el-button type="primary" round @click="onLogin">登 录</el-button>
          </el-form>
          <div class="login-bottom"><a  href="#"> 忘记密码</a> &nbsp; | &nbsp; <router-link to="/regist">新用户注册</router-link></div>
        </div>
      </el-col>
      <el-col :sm="0" :md="1" :lg="1"></el-col>
    </el-row>
  </div>  
</template>

<script>
import bus from '@/utils/eventBus.js'
export default {
  name: 'login',
  data () {
    return {
      // vcodeImg: this.$api.validatecode,
      dxkjy: {
        uid: '',
        // 用户名
        uname: '',        
        //企业名称
        nickName: '',
        //用户审批状态(0:待审批1:通过2:不通过)
        uaudit: '',
        // 获取到的凭证
        token: ''                
      },
      vcodeImg: '',
      form: {
        username: '',
        password: ''
        //vcode: ''
      },
      rules: {
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
          // { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
          // { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ]
        // vcode: [
        //   { required: true, message: '验证码不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  mounted () {    
  },
  methods: {
    onLogin () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在加载中...',
            // spinner: 'el-icon-loading',
            // background: 'rgba(0, 0, 0, 0.7)'
          })
          const { data: ret } = await this.$http.post1(this.$api.login, this.form)          
          // console.log('loginret=', ret)
          if (ret.code === 1) {
            //登录成功获取用户信息
            const { data: ret1 } = await this.$http.get(this.$api.userinfo)     
            // console.log('userinfoRet=', ret1)
            if (ret1.code === 1) {
              // 广播事件 用户登录成功传参用户名
              bus.emit('userLoginEvent', this.form.username)
              this.dxkjy.uid = ret1.data.id
              this.dxkjy.uname = ret1.data.username
              this.dxkjy.nickName = ret1.data.nickName              
              this.dxkjy.uaudit = ret1.data.auditStatus
              this.dxkjy.token = ret.data
              this.$storage.set('dxkjy-webui', this.dxkjy) 
              this.$router.push('/')
            } else {
              this.$message.error(ret1.msg)
              return
            }
            this.$message.success(ret.msg)
          } else {
            this.$message.error(ret.msg)
            // this.vcodeImg = this.$api.validatecode + '?' + new Date().getTime()
          } 
          loading.close()         
        } else {
          this.$message.error('验证没通过。')
          return false
        }
      })      
    }
  }
}
</script>

<style scoped>
.login_tit {  
  font-size: 20px;
  color:#333;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  border-bottom: 2px solid #9FB5CA;
  margin-bottom: 20px;
}
.login_box {
  margin: 40px auto;
  width: 270px;
  padding: 20px 40px;
  border: 1px solid #9FB5CA;
  background-color: #F4F7F9; 
  border-radius: 8px;
}
.login_box .vcode{
  vertical-align:middle;  
}
.el-input{
  font-size: 16px;
}
.el-button{
  width: 100%;
  font-size: 16px;
}
.login-bottom {
  margin-top: 16px;
  text-align:right
}

</style>